import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/DefaultLayout.tsx";
import ContactForm from "../components/ContactForm.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Falls Sie sich weitere Informationen wünschen oder eine unserer Veranstaltungen besuchen wollen, können Sie gerne Kontakt mit uns aufnehmen. Wir melden uns sehr gerne bei Ihnen.`}</p>
    <ContactForm mdxType="ContactForm" />
    <p><strong parentName="p">{`Zustimmung zur Datenspeicherung lt. DSGVO`}</strong></p>
    <p>{`Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an mannheim (at) bahai.de widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      